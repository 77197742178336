.card-icon {
  font-size: 32px;
  float: left;
}

#subsidiaries-btn {
  position: absolute !important;
  margin: 10px auto;
  bottom: 20px;
}

.custom-card:hover .card-icon {
  transform: scale(1.1);
  transition: 0.5s ease;
}
.custom-card {
  width: 18rem;
  margin: 0 10px;
  padding-top: 4%;
  position: relative;
  height: 470px !important;
  transition: box-shadow 0.3s ease;
}

.custom-card:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.3);
}

.icon-wrapper {
  top: 0;
  left: 10px;
}

@media (max-width: 768px) {
  .col-md-4 {
    margin-bottom: 20px;
  }
  .card-icon {
    font-size: 28px;
  }

  .custom-card {
    height: 450px !important;
    margin-bottom: 20px !important;
  }

  .card-title {
    font-size: 19px !important;
  }
  .card-text {
    font-size: 16px !important;
  }

  #subsidiaries-btn {
    position: absolute !important;
    margin: 10px 0 0 0;
    bottom: 10px;
  }
}
