.marquee-container {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.box-img {
  width: 400px;
  height: 130px;
  margin: 0 10px;
}

.box-img:hover {
  opacity: 0.7;
}

@media (max-width: 1200px) {
  .box-img {
    width: 250px;
    height: 90px;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .box-img {
    width: 170px;
    height: 60px;
    margin: 0;
    padding: 0;
  }
}

@media (max-width: 500px) {
  .box-img {
    width: 120px;
    height: 55px;
    margin: 0;
  }
}
