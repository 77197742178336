.gpt3__navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 7rem;
  background-color: transparent;
}

.gpt3__navbar-links {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
}

.gpt3__navbar-links_logo {
  margin-right: 2rem;
}

.gpt3__navbar-links_logo img {
  width: 62.56px;
  height: 16.02px;
}

.gpt3__navbar-links_container {
  display: flex;
  flex-direction: row;
}

.gpt3__navbar-sign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.gpt3__navbar-links_container p,
.gpt3__navbar-sign p,
.gpt3__navbar-menu_container p {
  font-weight: 500;
  font-size: 16px;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 1rem;
  cursor: pointer;
}

.gpt3__navbar-sign button,
.gpt3__navbar-menu_container button {
  padding: 0.5rem 1rem;
  border-radius: 5px;
  font-size: 15px;
  background-color: #ed1c24;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 5px;
  font-weight: 500;
  line-height: 25px;
}

.gpt3__navbar-menu {
  margin-left: 1rem;
  display: none;
  position: relative;
}

.gpt3__navbar-menu svg {
  cursor: pointer;
}

.gpt3__navbar-menu_container {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: #0f172a;
  padding: 1.5rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 220px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.gpt3__navbar-menu_container p {
  margin: 1rem 0;
}

.gpt3__navbar-menu_container-links-sign {
  display: none;
}

@media (max-width: 968px) {
  .gpt3__navbar {
    padding: 10px 3rem;
    font-size: 16px;
  }
}
@media (max-width: 868px) {
  .gpt3__navbar {
    padding: 10px 1rem;
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .gpt3__navbar-links_container {
    display: none;
  }

  .gpt3__navbar-menu {
    display: flex;
  }
  #nav-logo {
    width: 160px;
    height: auto;
  }
  .gpt3__navbar {
    padding-left: 25px !important;
    padding-right: 35px !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 552px) {
  .gpt3__navbar {
    padding: 2rem 4rem;
  }

  .gpt3__navbar_container-link-sign {
    display: none;
  }
}

@media (max-width: 550px) {
  .gpt3__navbar {
    padding: 2rem;
  }

  .gpt3__navbar-sign {
    display: none;
  }

  .gpt3__navbar-menu_container {
    top: 20px;
  }

  .gpt3__navbar-menu_container-links-sign {
    display: block;
  }
}

/* Animations  */

.scale-up-center {
  -webkit-animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1)
    both;
  animation: scale-up-center 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0;
}
