@media (max-width: 768px) {
  #img_box {
    width: 400px;
    margin: 20px auto;
    height: 250px;
  }

  img {
    width: 100%;
    height: 100%;
    margin: 20px auto;
  }
}
