.dropdown-menu {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0s linear 0.3s;
  position: absolute;
  top: 30px;
}

.dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s linear;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.3s ease, visibility 0s linear;
}

.nav-link.dropdown-button {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  color: inherit;
  font: inherit;
  outline: inherit;
}

.nav-link.dropdown-button::after {
  display: none; /* Hide the dropdown arrow */
}
