.header-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.header-container {
  position: relative;
  text-align: center;
  color: white;
}

#gutter {
  height: 200px;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  font-weight: bold;
}

#animatedTextBackground {
  display: flex;
  flex-flow: column;
  justify-content: center;
  width: 100%;
  height: 600px;
  justify-content: center;
  align-items: center;
  background: #181344;
}

#animatedTextHeading {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/907368/background-text.jpg")
    repeat;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  text-align: center;
  font-weight: 900;
  letter-spacing: -1px;
  font-size: 9vw;
  line-height: 0.8em;
  background-size: 300%;
}

@media (max-width: 768px) {
  #animatedTextBackground {
    height: 400px;
  }

  #gutter {
    height: 20px;
  }
}
