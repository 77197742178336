@media (max-width: 768px) {
  .header-text {
    font-size: 40px !important;
  }

  @media (max-width: 500px) {
    .header-text {
      font-size: 32px !important;
    }
  }
}
