.animated-caption {
  opacity: 0;
  transform: translateY(50%) !important;
  transition: opacity 2s, transform 0.5s !important;
}

.slide-image {
  height: 100vh;
  width: 100%;
  transition: transform 4s ease;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3);
}
.carousel-item.active .slide-image {
  transform: scale(1.1);
}

.carousel-item.active .animated-caption {
  opacity: 1;
  transform: translateY(0) !important;
}

.bold {
  font-weight: 700;
  font-size: 43;
  font-family: sans-serif;
}

@media (max-width: 768px) {
  #box {
    font-weight: normal;
    font-family: inherit;
  }

  .slider {
    height: 450px !important;
    z-index: -999 !important;
  }

  a.btn {
    padding: 5px 10px;
    height: auto;
    text-transform: uppercase;
    font-size: 14px;
  }

  .text-head {
    font-size: 20px;
    font-weight: bold;
  }
  .sub-text,
  p {
    font-size: 11px;
  }

  .bold {
    font-weight: 600;
    font-size: 32px;
    font-family: sans-serif;
  }
}
